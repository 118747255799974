/* -------------------mobile first------------------------ */

h1 {
  font-size: xx-large;
}
h3 {
  margin: 2rem 0;
  color: var(--danger);
}
h4 {
  font-weight: 600;
}
p {
  margin-bottom: 0.5rem;
}
ul {
  margin: 1rem 1rem;
  list-style-type: none;
}
.inline {
  display: inline;
}
.bullet {
  min-width: 0.5rem;
}
.bold {
  font-weight: bold;
}
.underline {
  margin: 1rem auto;
  width: 5rem;
  height: 0.3rem;
  background: var(--danger);
  border-radius: 0.1rem;
}
/* -------------------------------------------- */
main {
  background: url("./images/mainbg.jpg") center center/cover no-repeat fixed;

  position: relative;
  top: 3rem;
  opacity: 0.8;
  z-index: -2;
}
header {
  position: fixed;
  top: 0;
  width: 100vw;
  background: rgb(93, 90, 90) url("./images/mainbg.jpg") center center/cover
    no-repeat;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
#logo {
  height: 3rem;
  margin-left: 3rem;
}
#bars {
  font-size: 2rem;
  color: var(--info);
  margin-right: 2rem;
  /* z-index:2; */
}
#navbar {
  display: none;
  margin-right: 2rem;
}
.navlink {
  margin-right: 1rem;
  text-decoration: none;
  font-size: large;
  color: var(--important);
}
.navlink:hover {
  color: var(--important);
}
.side {
  height: 35vh;
  width: 8rem;
  font-size: x-large;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 3.5rem;
  right: -8rem;
  background: rgba(98, 177, 180, 0.3);
  border-radius: 10px;
  transition: transform 1s ease-in-out;
}
.show {
  transform: translateX(-12rem);
}
.sidelink {
  color: var(--danger);
  text-decoration: none;
  text-transform: uppercase;
}
/* ---------------------HOME----------------- */

.landing {
  width: 100vw;
}

#landing-img {
  width: 100%;
  opacity: 0.9;
  z-index: -1;
}
#landing-about {
  width: 85vw;
  margin: 0 auto;
  padding-bottom: 1rem;
  color: black;
  font-size: large;
  z-index: 1;
}
#name {
  font-size: 4rem;
  color: black;
  margin-left: -0.3rem;
  margin-bottom: 10rem;
}
#quote {
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
  color: var(--danger);
  margin-top: 1.5rem;
}
.section-2 {
  width: 100vw;
  background: #aca2cd;
  margin: 1rem auto;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.section-2 div {
  max-width: 95vw;
  margin-top: -1rem;
}
.section-2 ul {
  color: black;
  font-size: large;
  font-weight: 100;
  padding-left: 2rem;
}
/* --------------ABOUT---------------- */
.main-about {
  height: fit-content;
}
#about-img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.3;
  position: absolute;
  z-index: -1;
}
#about-head {
  text-align: center;
  padding-top: 1rem;
  z-index: 1;
}
.main-about article {
  font-size: large;
  position: relative;
  width: 85vw;
  /* height:150vh; */
  margin: 0 auto;
  color: #000;
}
.box {
  display: grid;
  gap: 0.3rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 5rem;
}
.box-item {
  border: 2px solid grey;
  border-radius: 0.3rem;
  background: rgba(98, 177, 180, 0.7);
  margin-top: 0.3rem;
  padding-bottom: 1rem;
}
.box-item img,
span {
  margin-left: 0.3rem;
  margin-top: 0.6rem;
}

.box-item img {
  width: 2rem;
  height: 2rem;
  margin-bottom: -0.5rem;
}
.text2 {
  font-size: medium;
}
.main-about ul {
  list-style: disc;
}
/* ---------------SERVICES------------------ */

#services-img {
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}
#services-img2 {
  width: 100vw;
  opacity: 0.2;
  position: absolute;
  top: 145vh;
  z-index: -1;
}

#services-head {
  text-align: center;
  padding-top: 1rem;
}
.main-services article {
  width: 85vw;
  padding-bottom: 5rem;
  font-size: medium;
  margin: 0 auto;
}
.headlinered {
  color: var(--danger);
}
.list2 .bullet {
  background-color: red;
}
/* -----------------CONTACT------------------- */
#section-contact {
  height: calc(100%-3rem);
  margin-bottom: 0.1rem;
}
#contact-img {
  width: 100vw;
  height: 63.8vh;
  opacity: 0.8;
  object-fit: cover;
  object-position: 43% 0;
}
#hire {
  text-align: center;
  /* background-color: var(--danger); */
  padding: 2rem 0;
  margin-top: -0.3rem;
}
#hands {
  font-size: 5rem;
  color: var(--important);
}
#hire h1 {
  color: rgb(144, 143, 143);
  margin-bottom: 3rem;
}

/* -------------FOOTER----------------- */
footer {
  bottom: 0;
  height: fit-content;
  color: #fff;
  background: url("./images/bubbly.jpg") no-repeat center center/ cover;
}

#footer-container {
  background-color: rgba(64, 63, 63, 0.747);
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
}
.location {
  margin-top: 1rem;
  margin-left: 1rem;
  color: #fff;
}
.location .bold {
  margin-bottom: 0.1rem;
}
.contact {
  height: 7rem;
}
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 2rem;
}
.mail {
  font-size: large;
  color: #fff;
  margin-top: 0.3rem;
  margin-right: 1.5rem;
}
.social-link {
  font-size: xx-large;
  color: var(--success);
}
#made-by {
  text-align: center;
  padding: 2px 0;
  font-size: smaller;
  color: #c4c7c7;
  background-color: #5b6062;
  height: 1.5rem;
}

/* XXXXXXXXXXXXXXXXXXXXXXX TABLET XXXXXXXXXXXXXXXXXXXXXXXXXXXX */
@media screen and (min-width: 600px) {
  /* --------------------HEADER-----------------*/
  #bars {
    display: none;
  }
  #navbar {
    display: block;
  }
  /* ---------------------HOME----------------- */
  #landing-img {
    height: calc(100vh- 3rem);
    object-fit: cover;
  }

  #landing-about {
    position: relative;
    top: -8vh;
    font-size: x-large;
    padding-bottom: 0;
  }
  #name {
    font-size: 4rem;
    color: black;
    margin-left: -0.3rem;
  }
  #quote {
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    color: var(--danger);
    margin-top: 1.5rem;
  }
  .section-2 {
    display: flex;
    justify-content: space-evenly;
  }
  .section-2 div {
    background: #a997b4;
    height: 10rem;
    max-width: 32vw;
    border-radius: 10px;
    margin: 1rem auto 2rem;
  }
  .section-2 ul {
    color: black;
    line-height: 2rem;
    padding: 0.3rem 0rem;
    font-size: large;
    font-weight: 100;
  }

  /* ------------------ABOUT------------------------ */

  #about-img {
    object-position: 0 5%;
  }
  #about-head {
    padding: 2rem;
  }
  .main-about article {
    position: relative;
    font-size: larger;
  }
  .box {
    column-gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .box-item img,
  span {
    margin-left: 0.2rem;
  }
  .text2 {
    font-size: 1rem;
  }
  /* ----------------------SERVICES--------------------- */

  #services-img2 {
    object-fit: cover;
    top: 100vh;
  }
  #services-head {
    padding-top: 2rem;
  }
  .main-services article {
    font-size: larger;
  }
  /* -----------------CONTACT--------------------- */
  #section-contact {
    height: calc(100%-3rem);
  }
  #hire {
    margin-top: -0.7rem;
  }
  #hire h1 {
    margin-top: 0.1rem;
  }

  /* -------------FOOTER-------------------------------------- */

  #footer-container {
    background-color: rgba(64, 63, 63, 0.747);
    padding-bottom: 0.3rem;
    display: flex;
    justify-content: space-around;
    line-height: 1.5rem;
  }
  .location .bold {
    margin-bottom: 0.1rem;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.1rem;
    margin-bottom: -0.2rem;
  }

  .social {
    flex-direction: row;
    gap: 1rem;
  }
  .social-link {
    font-size: xx-large;
    color: var(--success);
  }
  .mail {
    font-size: x-large;
  }
}
/* XXXXXXXXXXXXXXXXXXXXXXXXXX DESKTOP XXXXXXXXXXXXXXXXXXXXXXX */

@media screen and (min-width: 998px) {
  /* -----------------HOME------------------- */

  .section-2 div {
    font-size: x-large;
    padding-top: 0.5rem;
  }
  .section-2 div ul {
    font-size: x-large;
  }
  .box-item img,
  span {
    margin-left: 1rem;
  }
  .text2 {
    font-size: 1.3rem;
  }
  #services-img2 {
    top: 100vh;
  }
}

/* XXXXXXXXXXXXXXXXXXXXXX WIDESCREEN XXXXXXXXXXXXXXXXXXXX */

@media screen and (min-width: 1200px) {
  #logo {
    margin-left: 10vw;
  }
  #navbar {
    margin-right: 10vw;
  }
  #landing-img {
    max-height: calc(100vh - 3rem);
  }
  #landing-about {
    position: relative;
    top: -22vh;
    width: 75vw;
    margin-bottom: -10rem;
  }
  .main-about article {
    width: 77vw;
    margin: 0 auto;
  }
  .box {
    grid-template-columns: repeat(4, 1fr);
  }
  .box-item {
    padding: 1rem 0 2rem;
    max-width: 19vw;
  }
  .main-services article {
    width: 75vw;
    margin: 0 auto;
  }
  #services-img2 {
    top: 100vh;
    max-height: 80vh;
  }
  #contact-img {
    object-fit: cover;
    object-position: bottom;
  }
}
